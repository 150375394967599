console.log('It\'s working!')
var $ = require('jquery');
require('lazysizes');
var Flickity = require('flickity');
require('flickity-imagesloaded');

var flky = new Flickity( '.carousel', {
  imagesLoaded: true,
  pageDots: false,
  setGallerySize: false,
  lazyLoad: false,
  wrapAround: true
});

function preload(arr) {
  var images = [];
  for (var i = 0; i < arr.length; i++) {
    images[i] = new Image();
    images[i].src = arr[i];
  }
}

var s, Menu = {
  settings: {
    contentId: $('#content'),
    mainItem: $('.main-item'),
    secondaryItem: $('.has-children'),
    hoverItem: $('.hover-item'),
    shopItem: $('.shop-item'),
    shopMenu: $('li.shop'),
    backgroundImage: $('.background-image img')
  },
  bindUIAction: function(){
    // dont run any links...
    $('li.no-link a').on('click', function(event){
      if(!$(this).hasClass('mobile')) {
        event.preventDefault();
      }
    });
    $('.close-button').on('click', function(){
      if($(event.target).hasClass('secondary-item') || $(event.target).hasClass('tertiary-item')) { return }
      $('ul.secondary').each(function(){
        $(this).fadeOut(200);
      });
      $('body').removeClass('shop-menu-open');
      $(this).children('ul').fadeToggle(200);
    });
    // on mouse enter show submenu
    this.settings.mainItem.on('click', function(event){
      if($(event.target).hasClass('secondary-item') || $(event.target).hasClass('tertiary-item')) { return }
      $('#content').remove();
      $('ul.secondary').each(function(){
        $(this).fadeOut(200);
      });
      $('body').removeClass('shop-menu-open');
      $(this).children('ul').fadeToggle(200);
    });
    // on mouse enter show teritary
    this.settings.secondaryItem.on('click', function(event){
      if($(event.target).hasClass('tertiary-item')) { return }
      $('#content').remove();
      $('ul.tertiary').each(function(){
        $(this).fadeOut(200);
      });
      $(this).children('ul.tertiary').fadeToggle(200);
    });
    // on hover on hover-item
    this.settings.hoverItem.on('mouseenter', function(){
      $('#content').remove();
      $('body').removeClass('small');
      $('body').removeClass('start');
      var src = $(this).data('src');
      Menu.changeBackground(src);
    });
    this.settings.hoverItem.on('click', function(){
      $('#content').remove();
      if($('body').hasClass('start')) { return }
      $('body').toggleClass('small');
    });
    // on img click
    this.settings.backgroundImage.on('click', function(){
      $('#content').remove();
      if($('body').hasClass('start')) { return }
      $('body').toggleClass('small');
    });
    // shop items
    this.settings.shopItem.on('click', function(){
      var $this = $(this);
      $this.toggleClass('active');
      if($this.find('iframe')[0]){
        setTimeout(function(){
          //$this.find('iframe')[0].contentDocument.location.reload(true);
        }, 100)
      }
      $('.shop-item').each(function(){
        $(this).toggleClass('hidden');
      });
    });
    this.settings.shopMenu.on('click', function(){
      $('body').toggleClass('shop-menu-open');
    });
  },
  changeBackground: function(src) {
    $('#content').remove();
    this.settings.backgroundImage.attr('src', src);
  },
  init: function() {
    // kick things off
    s = this.settings;
    this.bindUIAction();
  }

};

(function() {
  Menu.init();

  // get all images
  var preloadImages = [];
  $('li.hover-item').each(function(){
    if($(this).data('src')) {
      preloadImages.push($(this).data('src'));
    }
  });
  preload(preloadImages);
})();
